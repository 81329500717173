import React, {FC, useCallback, useEffect, useState} from "react";

import DataTable from "react-data-table-component";

import {useActions} from "src/hooks/useActions";
import Input from "src/components/Input";
import Select from "src/components/Select";
import {ReactComponent as SearchIcon} from "src/assets/img/search.svg";
import {ReactComponent as EditIcon} from "src/assets/img/edit.svg";
import {ReactComponent as DeleteIcon} from "src/assets/img/delete.svg";
import Pagination from "src/components/Pagination";
import userDefaultImg from "src/assets/img/default-user.jpg";
import {
  CustomerRequestInterface,
  CustomerInterface,
  CustomerInfoInterface,
} from "src/types/customer";

import {Wrapper} from "./styled";
import {getAdminUsersAxios, removeAdminUserAxios, updateAdminUserAxios} from "../../../api/adminPanel";
import Modal from "../../../components/Modal";
import ExpandableTableList from "./EditableTable/ExpandableTableList";

const arrangeCustomerSelect = [
  {value: "last_name:asc", label: "Sort A-Z"},
  {value: "last_name:desc", label: "Sort Z-A"},
  {value: "id:asc", label: "Sort Oldest → Newest"},
  {value: "id:desc", label: "Sort Newest → Oldest"},
  // {value: "last_visit:desc", label: "Check-ins Most Recent → Oldest"},
  // {value: "last_visit:asc", label: "Check-ins Oldest → Most Recent"},
  // {value: "check_in_count:desc", label: "Sort Check-ins High → Low"},
  // {value: "check_in_count:asc", label: "Sort Check-ins Low → High"},
  // {value: "total_spend:desc", label: "Sort Points High → Low"},
  // {value: "total_spend:asc", label: "Sort Points Low → High"}
];


const AdminUsers: FC = () => {
  const [requestParameters, setRequestParameters] =
    React.useState<CustomerRequestInterface>({
      limit: "10",
      page: "1",
      search: null,
      sort_type: "last_name:asc",
      startDate: new Date(2021, 0, 1),
      endDate: new Date(),
      location: "",
    });
  const [customerListLoading, setCustomerListLoading] =
    React.useState<boolean>(true);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<CustomerInterface | null>(null);
  const [customerList, setCustomerList] =
    React.useState<Array<CustomerInterface> | null>(null);
  const [errorResponse, setErrorResponse] = useState<string>('')
  const [customerListInfo, setCustomerListInfo] =
    React.useState<CustomerInfoInterface>({
      count_users: 0,
      limit: 0,
      page: 0,
      total_pages_count: 0,
      total_users_count: 0,
    });
  
  const {getGroupsLocations} = useActions();
  useEffect(() => {
    getGroupsLocations();
  }, []); //eslint-disable-line
  
  const getCustomerList = useCallback(() => {
    setCustomerListLoading(true);
    getAdminUsersAxios({
      ...requestParameters
    }).then((response) => {
      setCustomerList(response.data.customers);
      setCustomerListInfo({
        count_users: Number(response.data.count_users),
        limit: Number(response.data.limit),
        page: Number(response.data.page),
        total_pages_count: Number(response.data.total_pages_count),
        total_users_count: Number(response.data.total_users_count),
      });
      setCustomerListLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  },[requestParameters]);
  
  const handleChangeRequestParamtrs = (
    name: string,
    value: string | number | Date
  ) => {
    setRequestParameters((prevState: any) => {
      return {...prevState, [name]: value};
    });
  };
  
  useEffect(() => {
    getCustomerList();
  }, [
    requestParameters.page,
    requestParameters.sort_type,
    requestParameters.searchType,
  ]);
  
  useEffect(() => {
    if (requestParameters.search !== null) {
      const timeout = setTimeout(() => {
        getCustomerList();
      }, 500);
      
      return () => clearTimeout(timeout);
    }
  }, [requestParameters.search]);
  
  useEffect(() => {
    if (Number(requestParameters.page) === 1 && customerListInfo.limit !== 0) {
      getCustomerList();
    }
  }, [requestParameters.limit]);
  
  const editUser = (id: number, row: CustomerInterface) => {
    const editRow = {...row, edit: true}
    // @ts-ignore
    setCustomerList([...customerList.map(item => item.id === id ? item = editRow : item)])
  }
  
  const saveEditUser = (id: number) => {
    const editedUser = customerList?.find((item) => item.id === id)
    const requestData = {
      id: id,
      first_name: editedUser?.first_name,
      last_name: editedUser?.last_name,
      email: editedUser?.email,
      phone: editedUser?.phone
    }
    updateAdminUserAxios(requestData).then(res=>{
      getCustomerList()
    })
  }
  
  const removeUser = (id: number) => {
    removeAdminUserAxios({id}).then(res=>{
      getCustomerList()
      setShowDeleteUserModal(false)
    }).catch(err=>{
      console.log(err)
      setShowDeleteUserModal(false)
      setErrorResponse(err.data.error)
    })
  }
  
  const checkDeleteUser = (user: CustomerInterface) =>{
    setShowDeleteUserModal(true)
    setSelectedUser(user)
  }
  
  const customerTableColumns = [
    {
      name: "",
      width: "50px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div className="photo">
            {row.photo ? (
              <img src={row.photo} alt=""/>
            ) : (
              <img src={userDefaultImg} alt=""/>
            )}
          </div>
        );
      }
    },
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (!row.edit ? (<div>
            {row.first_name} {row.last_name}
          </div>) : (<div className={'edit_input_wrap'}>
            <Input placeholder={'First Name'} defaultValue={row.first_name} getValue={(value) => {
              row.first_name = value
            }}/>
            <Input placeholder={'Last Name'} defaultValue={row.last_name} getValue={(value) => {
              row.last_name = value
            }}/>
          </div>)
        
        );
      }
    },
    {
      name: "Email",
      minWidth: "225px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (!row.edit ? (<div>{row.email}</div>) : (
          <div className={'edit_input_wrap'}><Input placeholder={'Email'} defaultValue={row.email}
                                                    getValue={(value) => {
                                                      row.email = value
                                                    }}/></div>));
      },
    },
    {
      name: "Phone",
      minWidth: "175px",
      maxWidth: '300px',
      cell: (row: CustomerInterface): JSX.Element => {
        return (!row.edit ? (<div>{row.phone}</div>) : (
          <div className={'edit_input_wrap'}><Input placeholder={'Phone'} defaultValue={row.phone}
                                                    getValue={(value) => {
                                                      row.phone = value
                                                    }}/></div>));
      },
    },
    {
      name: "",
      maxWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div className="action">
            {!row.edit ? (
              <>
                <button
                  type="button"
                  className="edit"
                  onClick={() => editUser(row.id, row)}
                >
                  <EditIcon/>
                </button>
                <button
                  type="button"
                  className="delete"
                  onClick={() => checkDeleteUser(row)}
                >
                  <DeleteIcon/>
                </button>
              </>
            ) : (
              <button
                className="btn-blue-outline save"
                style={{padding: "10px 20px"}}
                onClick={() => saveEditUser(row.id)}
              >
                Save
              </button>
            )}
          </div>
        );
      },
    },
  ];
  
  return (
    <Wrapper>
      <div className="page-title">Admin Users</div>
      <div className="top-bar">
        <div className="search-wrap">
          <Input
            placeholder="Search..."
            required
            icon={<SearchIcon/>}
            getValue={(value) => setRequestParameters({...requestParameters, search: value})}
          />
        </div>
        <div className="groups-wrap">
          <Select
            placeholder="Arrange Customer List"
            selectorList={arrangeCustomerSelect}
            positionForShow="bottom"
            defaultValue={arrangeCustomerSelect[0]}
            required
            getValue={(value) =>
              handleChangeRequestParamtrs("sort_type", value)
            }
            hideSelectorFilter
          />
        </div>
      </div>
      <div
        className={`table-wrap ${customerListLoading ? "loading-blue" : ""}`}
      >
        <div className="loading-innerwrap">
          {customerList !== null ? (
            customerList.length > 0 ? (
              <>
                <div className="table">
                  <DataTable
                    noHeader
                    columns={customerTableColumns}
                    data={customerList}
                    expandableRows
                    expandableRowsComponent={
                      <ExpandableTableList data={customerList} setcustomerListLoading={setCustomerListLoading} getCustomerList={getCustomerList}
                                           requestParamtrs={requestParameters} />
                    }
                  />
                </div>
                <Pagination {...customerListInfo} requestLimit={requestParameters.limit}
                            setRequestParamtrs={setRequestParameters}/>
              </>
            ) : (
              <div className="nothing-found">Sorry, nothing found</div>
            )
          ) : null}
        </div>
      </div>
      {errorResponse && (
        <Modal
          openModal={!!errorResponse}
          setOpenModal={() => setErrorResponse("")}
          closeOutsideClick={true}
          style={{maxWidth: '900px'}}
        >
          <div className="modal-content">
            <div className="title">Error</div>
            <div className="sub-title">{errorResponse}</div>
          </div>
        </Modal>
      )}
      {showDeleteUserModal && (
        <Modal
          openModal={showDeleteUserModal}
          setOpenModal={() => setShowDeleteUserModal(false)}
          closeOutsideClick
        >
          <div
            className={`delete-modal`}
          >
            <div className="loading-innerwrap">
              <div className="title">Are you sure you want to delete the User &quot;
                {selectedUser?.email}&quot;?</div>
              <div className="btn_wrap">
                <button
                  type="button"
                  className="btn-red"
                  onClick={() => {
                    setSelectedUser(null)
                    setShowDeleteUserModal(false)
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-blue"
                  onClick={() => {
                    removeUser(selectedUser!.id)
                  }}
                >
                  Confirm delete
                </button>
              </div>
            
            </div>
          </div>
        </Modal>
      )}
    </Wrapper>
  );
};

export default AdminUsers;
