import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminWrapper from "src/components/AdminWrapper";

import Dashboard from "src/views/Dashboard";
import AdminDashboard from "./views/AdminPages/AdminDashboard";
import AdminBusinesses from "./views/AdminPages/AdminBuisneses";
import AdminUsers from "./views/AdminPages/AdminUsers";
import UserProfile from "src/views/UserProfile";
import CustomerList from "src/views/Customers/CustomerList";
import CustomerGroups from "src/views/Customers/CustomerGroups";
import CustomerGroupsCreateEdit from "src/views/Customers/CustomerGroupsCreateEdit";
import QuickCampaign from "src/views/Campaigns/QuickCampaign";
import ManageCampaigns from "src/views/Campaigns/ManageCampaigns";
import Promotion from "src/views/Promotions/Promotion";
import PromotionManage from "src/views/Promotions/PromotionManage";

import Empty from "src/views/Empty";
import OrdersList from "src/views/Orders";
import SignIn from "src/views/SignIn";
import ForgotPassword from "src/views/ForgotPassword";
import ResetPassword from "src/views/ResetPassword";
import Registration from "src/views/Registration";
import DeclineAccount from "src/views/DeclineAccount";
import PlaceApprove from "src/views/PlaceApprove";
import StaffMembers from "./views/StaffMembers";
import PosRules from "./views/PosRules/PosRules";
import ExternalPosIntegration from "./views/ExternalPosIntegration";
import ConfirmLightSpeed from "./views/ConfirmLightSpeed";
import AdminAdverts from "./views/AdminPages/AdminAdverts";
import AdminAdvertsOutlets from "./views/AdminPages/AdminAdverts/Outlets";
import AdminAdvertsBrands from "./views/AdminPages/AdminAdverts/Brands";
import EcommerceRule from "./views/EcommerceRule/EcommerceRule";
import StaffDiscount from "./views/StaffDiscount";
import ManageNotification from "./views/AdminPages/Notification/ManageNotification";
import QuickNotification from "./views/AdminPages/Notification/QuickCampaign";
import MinimumSpend from "./views/MinimumSpend";
import SitePosRules from "./views/PosRules/SitePosRules";
import AdminBusinessesSetings from "./views/AdminPages/AdminBusinessesSetings";
import ProductDiscount from "./views/ProductDiscount";
import SpendCard from "./views/SpendCard";
import StaffDiscountMembers from "./views/StaffDiscountMembers/StaffDiscountMembers";
import StaffDiscountDashboard from "./views/StaffDiscountDashboard/StaffDiscountDashboard";
import EposNow from "./EposNow/EposNow";
import KlaviyoIntegration from "./views/KlaviyoIntegration";
// import Feedback from "src/views/Feedback";
const role = JSON.parse(localStorage.getItem("user")!)?.role

const Routers: React.FC = () => {
  return (
    <BrowserRouter>
      {localStorage.getItem("bearer_token") ? (
        <AdminWrapper>
          <Switch>
            <Route exact path="/" component={role !== 3 ? Dashboard : AdminDashboard} />
            <Redirect
              exact
              from="/account-management"
              to="/account-management/edit-personal-details"
            />
            <Route
              exact
              path="/account-management/:subpage"
              component={UserProfile}
            />
            <Redirect
              exact
              from="/campaigns"
              to="/campaigns/manage-campaigns"
            />
            <Route
              exact
              path="/campaigns/manage-campaigns"
              component={ManageCampaigns}
            />
            <Route
              exact
              path="/campaigns/create-campaign"
              component={QuickCampaign}
            />
            <Redirect
              exact
              from="/notification"
              to="/notification/manage-notification"
            />
            <Route
              exact
              path="/notification/manage-notification"
              component={ManageNotification}
            />
            <Route
              exact
              path="/notification/create-notification"
              component={QuickNotification}
            />
            <Route
              exact
              path="/notification/edit-notification/:id"
              component={QuickNotification}
            />
            <Route
              exact
              path="/admin-businesses"
              component={AdminBusinesses}
            />
            <Route
              exact
              path="/businesses-settings"
              component={AdminBusinessesSetings}
            />
            <Route
              exact
              path="/minimum-spend"
              component={MinimumSpend}
            />
            <Route
              exact
              path="/admin-users"
              component={AdminUsers}
            />
            <Route
              exact
              path="/campaigns/edit-campaign/:id"
              component={QuickCampaign}
            />
            {/* <Redirect
              exact
              from="/your-loyalty-program"
              to="/your-loyalty-program/manage-promotion"
            /> */}
            <Route exact path="/your-loyalty-program" component={Promotion} />
            <Route
              exact
              path="/outlets-and-users"
              component={PromotionManage}
            />
            <Route
              exact
              path="/your-loyalty-program/edit-promotion"
              component={Empty}
            />
            <Route
              exact
              path="/admin-adverts"
              component={AdminAdverts}
            />
            <Route
              exact
              path="/admin-adverts/offers-outlets"
              component={AdminAdvertsOutlets}
            />
            <Route
              exact
              path="/admin-adverts/offers-brands"
              component={AdminAdvertsBrands}
            />
            <Route
              exact
              path="/your-loyalty-program/create-promotion"
              component={Empty}
            />
            <Redirect exact from="/customers" to="/customers/customer-list" />
            <Route
              exact
              path="/customers/customer-list"
              component={CustomerList}
            />
            <Route
              exact
              path="/customers/customer-groups"
              component={CustomerGroups}
            />
            <Route
              exact
              path="/customers/create-customer-group"
              component={CustomerGroupsCreateEdit}
            />
            <Route
              exact
              path="/e-commerce"
              component={EcommerceRule}
            />
            <Route
              exact
              path="/customers/edit-customer-group/:id"
              component={CustomerGroupsCreateEdit}
            />
            {/* <Route exact path="/feedback" component={Feedback} /> */}
            <Route exact path="/staff-members" component={StaffMembers} />
            <Route exact path="/staff-discount" component={StaffDiscount} />
            <Route exact path="/staff-discount-members" component={StaffDiscountMembers} />
            <Route exact path="/staff-discount-dashboard" component={StaffDiscountDashboard} />
            <Route exact path="/product-discount" component={ProductDiscount} />
            <Route exact path="/vouchers" component={Empty} />
            <Route exact path="/rewards" component={Empty} />
            <Route exact path="/pos-rules" component={PosRules} />
            <Route exact path="/site-pos-rules" component={SitePosRules} />
            <Route exact path="/spend-card" component={SpendCard} />
            <Route exact path="/external-pos-integration" component={ExternalPosIntegration} />
            <Route exact path="/klaviyo-integration" component={KlaviyoIntegration} />
            <Route exact path="/confirm-external-pos-integration" component={ConfirmLightSpeed} />
            <Route exact path="/instant-deals" component={Empty} />
            <Route exact path="/online-shop" component={Empty} />
            <Route exact path="/orders" component={OrdersList} />
            <Route exact path="/reports" component={Empty} />
            <Redirect to="/" />
          </Switch>
        </AdminWrapper>
      ) : (
        <Switch>
          <Route exact path="/login" component={SignIn} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/registration" component={Registration} />
          <Route exact path="/decline-account" component={DeclineAccount} />
          <Route exact path="/approve/:hash" component={PlaceApprove} />
          <Route exact path="/eposnow" component={EposNow} />
          <Redirect to="/login" />
        </Switch>
      )}
    </BrowserRouter>
  );
};

export default Routers;
