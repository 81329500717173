import React, {useCallback, useEffect, useState} from "react";
import { Wrapper } from "./styled";
import { createLightSpeed, deleteLightSpeed, getLightSpeed} from "../../api/lightSpeed";
import { ReactComponent as CirclePlus } from "../../assets/img//circle-plus.svg";
import Input from "../../components/Input";
import DataTable from "react-data-table-component";
import { ReactComponent as CloseIcon } from "../../assets/img/clear.svg";
import { ReactComponent as CheckCircle } from "../../assets/img/checkCircle.svg";
import { ReactComponent as Edit } from "../../assets/img/edit.svg";
import { ReactComponent as Delete } from "../../assets/img/delete.svg";
import Modal from "../../components/Modal";
import {createKlaviyoIntegration, deleteKlaviyoIntegration, getKlaviyoIntegration} from "../../api/klaviyo";

const KlaviyoIntegration = () => {
  const [klaviyoBuisnes, setKlaviyoBuisnes] = useState([]);
  // const [clientIdInput, setClientIdinput] = useState("");
  // const [clientSecretInput, setClientSecretinput] = useState("");
  const [privatKey, setPrivatKey] = useState("");
  const [listName, setListName] = useState("");
  const [addKlaviyo, setAddKlaviyo] = useState(false);
  useEffect(() => {
    getKlaviyo()
  }, []);

  const getKlaviyo = useCallback(() => {
    getKlaviyoIntegration().then((res) => {
      console.log(res)
      setKlaviyoBuisnes(res.data);
    });
  },[])

  const handleAddLightSpeed = (event: React.FormEvent) => {
    event.preventDefault();
    const requestObject = {
      api_key: privatKey,
      list_name: listName
    };
    closeAddForm()
    createKlaviyoIntegration(requestObject).then(res => {
      getKlaviyo()
    });
  };
  const [deleteKlaviyoIntegrate, setDeleteKlaviyoIntegrate] = useState<boolean>(false);
  
  const removeKlaviyo = () =>{
    deleteKlaviyoIntegration().then(res=>{
      getKlaviyo()
      setDeleteKlaviyoIntegrate(false)
    })
  }

  const closeAddForm = () => {
    setAddKlaviyo(false)
    setPrivatKey('')
    setListName('')
    setPrivatKey('')
  }
  
  return (
    <Wrapper>
      <h1>Klaviyo Integration</h1>
      {klaviyoBuisnes.length === 0 ? <div className="locations">
        <button className="btn-white add-stampCategory" onClick={() => {
          setAddKlaviyo(true);
        }}>
            <span className="icon">
              <CirclePlus/>
            </span>
          <span>Add Klaviyo Account</span>
        </button>
        {addKlaviyo ? (
          <div className="create-location">
            <form
              onSubmit={handleAddLightSpeed}
            >
              <div className="loading-innerwrap">
                <div className="title-close">
                  <div className="title">Connect Klaviyo Account</div>
                  <button
                    type="button"
                    className="close"
                    onClick={closeAddForm}
                  >
                    <CloseIcon/>
                  </button>
                </div>
                <div className="add-location-wrapper">
                  <div className="left-block">
                    <div className="input-wrap">
                      <Input
                        required
                        type={'password'}
                        placeholder="Please enter privat key"
                        getValue={(value) => setPrivatKey(value)
                        }
                      />
                    </div>
                    <div className="input-wrap">
                      <Input
                        required
                        placeholder="Please enter List Name"
                        getValue={(value) => setListName(value)
                        }
                      />
                    </div>
                  
                  
                  </div>
                  
                  <button type="submit" className="btn-blue">
                    Add Klaviyo Profile
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : null}
      </div> : <div className="content">
        <h2>You are connected to Klaviyo</h2>
        <button className="btn-blue" onClick={()=>setDeleteKlaviyoIntegrate(true)}>
          Remove Klaviyo Integration
        </button>
      </div>}
      
      
      {deleteKlaviyoIntegrate && (
        <Modal
          openModal={!!deleteKlaviyoIntegrate}
          setOpenModal={() => setDeleteKlaviyoIntegrate(false)}
          closeOutsideClick
        >
          <div
            className={'remove-modal'}
          >
            <div className="loading-innerwrap">
              <div className="title">
                Please confirm delete Klaviyo Integration?
              </div>
              <button
                type="button"
                className="btn-blue"
                onClick={() => {
                  removeKlaviyo()
                }}
              >
                Confirm delete
              </button>
            </div>
          </div>
        </Modal>
      )}
    
    
    </Wrapper>
  );
};

export default KlaviyoIntegration;
